<template>
  <div>
    <b-alert
      variant="warning"
      show
    >
      <div class="alert-body">
        <p class="text-center">
          <strong>This Page Is Under Construction</strong>
        </p>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
}
</script>
